import React from "react";

import Layout from "layout/index";

import SEO from "components/seo/index";

import Saravanan from "assets/images/ourteam/MaskGroup20.png";

import Sangeeth from "assets/images/ourteam/MaskGroup21.png";

import Ramakantha from "assets/images/ourteam/MaskGroup22.png";

import Ananya from "assets/images/ourteam/MaskGroup23.png";
import ashok from "assets/images/ourteam/Dr_Ashok_Saxena.png";
import sairam from "assets/images/ourteam/Dr_Sairam_Reddy.png";
import nithya from "assets/images/ourteam/Nitya_Reddy_Beravol.png";
import vishwas from "assets/images/ourteam/vishwas_subramanyam.png";
import Jeyabala from "assets/images/ourteam/Jeyabala.png";

import { isMobile } from "react-device-detect";

import "assets/styles/pages/ourteam.scss";

const Ourteam = () => {
  return (
    <>
      <SEO title="Forests By Heartfulness | FAQS" />
      <Layout>
        <div className="ourteamseaction inner-pages pb-0">
          <div className="container our-team-container">
            <div className="row ">
              <div className="col-md-12">
                <h2 className="text-start meet-team-title">Meet Our Team</h2>
              </div>
            </div>
            <div
              className={
                isMobile
                  ? "row  row-section flex-column-reverse"
                  : "row  row-section "
              }
            >
              <div className="col-md-3">
                <img src={Ramakantha} className="our-team-image-wrapper" />
              </div>
              <div className="col-md-9 description-section">
                <h5>Dr. V Ramakantha</h5>
                <p>
                  Dr. V Ramakantha was a member of the Indian Forest Service and
                  has superannuated as the Principal Chief Conservator of
                  Forests. He is an academician, author and internationally
                  acclaimed wildlife photographer. He has had the experience of
                  managing a few of the ecologically important, species-rich
                  ecosystems of India. Post his superannuation, he moved to
                  Kanha Shanti Vanam and as a key member of the greening team he
                  now holds the position of Director, Forests by Heartfulness.{" "}
                  <br></br>
                  <b>
                    He specializes in both ex-situ and in-situ conservation of
                    red-listed species and has successfully created a swathe of
                    rain-forest in the inhospitable soil conditions and dry /
                    torrid climate of Ranga Reddy District of Telangana.
                  </b>
                </p>
              </div>
            </div>
            <div className="row row-section">
              <div className="col-md-9 description-section">
                <h5>Sangeeth Parvatam</h5>
                <p>
                  Sangeeth Parvatam works as a Product Manager in the energy
                  sector and is associated with exploring Nature-Based Solutions
                  as a viable option for achieving Net Zero carbon emissions,
                  both for corporates and countries alike. He strongly believes
                  and advocates that climate change abatement is an opportunity
                  to build a just and equitable society involving grassroots
                  communities. He leads the Forests By Heartfulness initiative
                  as the program manager helping with strategy, industry
                  outreach, and leading collaborative efforts with other NGO
                  partners.
                </p>
              </div>
              <div className="col-md-3">
                <img src={Sangeeth} className="our-team-image-wrapper" />
              </div>
            </div>
            <div
              className={
                isMobile
                  ? "row  row-section flex-column-reverse"
                  : "row  row-section "
              }
            >
              <div className="col-md-3">
                <img src={Saravanan} className="our-team-image-wrapper" />
              </div>
              <div className="col-md-9 description-section">
                <h5>Saravanan</h5>
                <p>
                  Saravanan is the Master Gardener of Kanha Shanti Vanam, the
                  Global Meditation Center for Heartfulness Institute. He is
                  among the key persons responsible for transforming the once
                  barren land into a green oasis with rich bio-diversity with
                  over 50,000 standing trees and over 2 lakh planted saplings.
                  He comes with decades of nursery management experience and is
                  a tremendous entrepreneur himself. He is the proprietor of
                  landscaping, and design company and of a prominent nursery in
                  Tamilnadu and sits on the board of directors of Heartyculture
                  Products company.
                </p>
              </div>
            </div>
            <div className="row row-section">
              <div className="col-md-9 description-section">
                <h5>Dr. Ananya S Rao</h5>
                <p>
                  Dr. Ananya S Rao has a Ph.D. from the Centre for Atmospheric
                  and Oceanic Sciences, Indian Institute of Science, Bangalore,
                  India. She has had the privilege of working with pioneers of
                  climate change research in India and has studied the carbon in
                  soil and biomass in India. Currently working as a consultant
                  with UNESCO MGIEP, she has been a part of the ex-situ
                  conservation project at Kanha Shantivanam, Hyderabad and is a
                  Senior Scientist at Forest by Heartfulness.
                </p>
              </div>
              <div className="col-md-3">
                <img src={Ananya} className="our-team-image-wrapper" />
              </div>
            </div>
            <div
              className={
                isMobile
                  ? "row  row-section flex-column-reverse"
                  : "row  row-section "
              }
            >
              <div className="col-md-3">
                <img src={sairam} className="our-team-image-wrapper" />
              </div>
              <div className="col-md-9 description-section">
                <h5>Dr Sairam Reddy Palicherla:</h5>
                <p>
                  Dr. Sairam Reddy Palicherla is a plant biotechnologist and
                  entrepreneur. For over 30 years, he has been conducting his
                  research in the areas of sustainable agriculture and tree
                  conservation, and made significant contribution
                </p>
              </div>
            </div>
            <div className="row row-section">
              <div className="col-md-9 description-section">
                <h5>Dr Ashok Saxena</h5>
                <p>
                  Dr. Ashok Saxena was the Chairman of Gujarat Biodiversity
                  Board, Gandhinagar and has superannuated as the Principal
                  Chief Conservator of Forests of Indian Forest Service. He has
                  directly supervised afforestation and extension activities in
                  over 4000 Villages of Gujarat, India. He is among the chief
                  advisors for FBH in the areas of legal, governance and long
                  term strategy.
                </p>
              </div>
              <div className="col-md-3">
                <img src={ashok} className="our-team-image-wrapper" />
              </div>
            </div>
            <div
              className={
                isMobile
                  ? "row  row-section flex-column-reverse"
                  : "row  row-section "
              }
            >
              <div className="col-md-3">
                <img src={vishwas} className="our-team-image-wrapper" />
              </div>
              <div className="col-md-9 description-section">
                <h5>Vishwas Subramanyam</h5>
                <p>
                  Vishwas Subramanyam helps with fundraising and partnerships at
                  Heartfulness Institute, has helped foster key partnerships
                  between #ForestsByHeartfulness and various corporations and
                  non-profits. He also contributes to partnership building for
                  Heartfulness Institute outside of the #ForestsByHeartfulness
                  initiative, connecting with corporations, nonprofits, and
                  significant public figures to introduce the Heartfulness
                  initiatives.
                  <br />
                  Vishwas is currently a Management Consultant with several
                  years of consulting experience, having previously worked at
                  Deloitte and Altimetrik.
                </p>
              </div>
            </div>

            <div className="row row-section">
              <div className="col-md-9 description-section">
                <h5>Ms Nitya Reddy Beravol</h5>
                <p>
                  A fashion designer by profession, she worked in one of India’s
                  top fashion design firms and eventually had her own bridal
                  couture line. In 2017, she found her true calling in
                  environment - starting the ‘Each one,Teach one, to Plant one’
                  campaign. Now an environmental enthusiast, dedicating her full
                  time to both her passions - teaching meditation and working
                  with environment. Her vision is to see model communities
                  across the world, where humans and nature co-exist in harmony.
                  She now heads the Global Sustainability initiatives of
                  Heartfulness across 160 countries
                </p>
              </div>
              <div className="col-md-3">
                <img src={nithya} className="our-team-image-wrapper" />
              </div>
            </div>

            <div
              className={
                isMobile
                  ? "row  row-section flex-column-reverse"
                  : "row  row-section "
              }
            >
              <div className="col-md-3">
                <img src={Jeyabala} className="our-team-image-wrapper" />
              </div>
              <div className="col-md-9 description-section">
                <h5>Jeyabala</h5>
                <p>
                  Jeyabala leads various tree planting initiatives across all
                  Heartfulness meditation centers in India. He is instrumental
                  in setting up nationwide network of 18 nurseries for
                  plantation activities of Forests By Heartfulness. He brings to
                  table deep handson experience on entire lifecycle of tree
                  plantation from species selection to production of seedlings
                  to plantation and maintenance. He has been a Heartfulness
                  meditation practitioner for the last 15 years and is the
                  founding member of Forests By Heartfulness initiative.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Ourteam;
